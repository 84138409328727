.App-bg{
    height: inherit;
    background: radial-gradient(300% 1800px at top, #A20F35 0%, #000000 15%);
  }
  
  .App-logo {
    align-content: center;
  }
  
  .header-container{
    padding: 32px 0px ;
  }