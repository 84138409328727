.working-container{
    padding: 32px 24px;
    width: 100%;
}

.landing-btn button{
    width: 360px;
}

@media screen and (max-width:480px){
 .landing-btn button{
     width: 100% !important;
 }   
}