// imports are pulled from the src folder
// we don't need to write src or the _ prefix

@import '../components/header-text/header-text.scss';

// Overrides
$primary:    #80B602 !default;
$secondary:    #FFECC4 !default;
$body:  #E0DEDE !default;
$white:  white !default;
$dark: #000000 !default;
$lightDark: #222 !default;
$grey: #7D7D7D !default;
$darkGrey: #333 !default;

@import './node_modules/bootstrap/scss/bootstrap.scss';

h1, .h1 { 
    font-size: 1.63rem;
    font-weight: normal; 
      @media (min-width: 768px) {
        @include font-size($h3-font-size);
      }
}
h2, .h2 { 
    @include font-size($h5-font-size);
    font-weight: normal;
  }
h6, .h6 {
  @include font-size($h5-font-size);
  @media (min-width: 768px) {
    @include font-size($h6-font-size);
  }
}
p {
  color: #E0DEDE;
  font-size: 1.06rem;
  letter-spacing: 0.8px;
  @media (min-width: 768px) {
    font-size: 1rem;
    letter-spacing: 0.2px;
  }
}
.font-weight-bold {
  font-weight: 500 !important;
}

html, body {
    height: 100%;
    min-height: 100%;
    font-family: 'Roboto', sans-serif;
}
body {
    color: $white;
    background-color: $dark;
}
.container-main {
  max-width: 768px;
  padding-bottom: 1rem;
}
.btn-primary, .btn-secondary {
  background-color: $primary;
  border-radius: 100px;
  min-height: 52px;
  font-size: 1rem;
  color: $dark;
  font-weight: 500;
  &:focus,
  &:active,
  &:hover {
    color: $dark;
  }
}
.btn-secondary {
  border: none;
  background: $lightDark;
  color: white;
  &:focus,
  &:active,
  &:hover {
    background: $darkGrey !important;
    color: white !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 206, 173, 0.5);
  }
}
.title, .sub-title {
    color: $secondary;
}
.form-sub-headings {
  margin-top: 1rem;
  margin-bottom: 1rem;
  &::after {
    content: '';
    width: 30px;
    height: 2px;
    display: block;
    background-color: $primary;
    margin-top: 0.3rem;
  }
}
form {
  select {
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-position: calc(100% - 15px) 60%;
    background-size: 12px;
    option {
      background: $darkGrey;
      color: white;
    }
    optgroup {
      @-moz-document url-prefix() {
        color: $dark;
      }
      background: $darkGrey;
      option {
        color: $dark;
        color: white;
      }
    }
  }
  label {
    opacity: 0.55;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  .form-control {
    background-color: #1D1D1D;
    border-color: $grey;
    min-height: 48px;
    color: $white;
    &:focus {
      background-color: inherit;
      color: $white;
    }
  }
  button {
    margin-top: 1rem;
  }
  .form-control:disabled, .form-control[readonly] {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #121415;
}
.dropdown-community-selection {
  button {
    background-color: transparent !important;
    border: 1px solid $grey !important;
    color: $white;
    border-radius: 6px;
    min-height: 54px;
    text-align: left;
    // padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dropdown-toggle {
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }
  .dropdown-menu {
    background: $dark;
    border: 1px solid $grey;
    width: 100%;
    max-height: 60vh;
    overflow: hidden;
    overflow-y: auto;

    .dropdown-item {
      color: $white;
      padding: 0.2rem 1rem;
      &:focus, 
      &:active,
      &:hover {
        background-color: $primary;
        color: $dark;
      }
    }
  }
}
.thx-wrapper {
  border: 2px solid #80B602;
  border-left: none;
  border-right: none;
  padding: 2rem 0;
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 400;
}
.bg-dark {
  background-color: $lightDark !important;
}

.invalid-feedback {
  display: contents;
}

.alert-custom-card {
  border-radius: 30px;
  padding: 2rem;
  margin-top: 2rem;

}
.w-md-70, .w-50 {
  max-width: inherit !important;
  width: 100% !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #7D7D7D;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

@include media-breakpoint-up(md) {
  .w-md-70 {
    max-width: 570px !important;
  }
  .w-50 {
    width: 50% !important;
  }
}
@include media-breakpoint-up(sm) {
  .alert-custom-card {
    max-width: 400px;
    margin: 2rem auto 0 auto;
  }
  .w-md-70, .w-50 {
    width: 80% !important;
  }
}






/* = Date picker field
  ---------------------- ------------------ */
  .react-calendar__month-view__weekdays {
    font-size: 0.75rem;
  }
  .react-calendar {
    background: #080808 !important;
    border-color: #444 !important;
    box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.8) !important;
  }
  .react-calendar button {
    color: inherit !important;
    font-size: 0.95rem;
  }
  .react-calendar__tile:disabled,
  .react-calendar__navigation button[disabled] {
    background-color: #0e0e0e !important;
    opacity: 0.4 !important;
  }
  .react-calendar__tile--active {
    background: #80b602 !important;
    color: black !important ;
  }
  .date {
    display: block !important;
    height: calc(2rem + 2px);
    border: 1px solid !important;
    border-radius: 0.25rem !important;
    border-color: #242527 !important;
    background: #121415 !important;
    /* margin: 0rem 0.5rem 1rem 0 !important; */
    width: 100% !important;
  }
  .date-required {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-color: #3c3a3a;
    background: none;
    width: 100%;
  }
  .date-required .react-date-picker--enabled {
    // border-left: 2px solid #80b602 !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }
  .date-required .react-date-picker {
    border-left: 2px solid red;
    text-align: left;
    width: calc(100% - 1.5%);
    padding-left: 1.5%;
  }
  .react-calendar .react-calendar__tile:enabled:hover,
  .react-calendar .react-calendar__tile:enabled:focus,
  .react-calendar .react-calendar__tile--hasActive {
    background-color: #94d300 !important;
    color: black !important;
  }
  .date-required .react-date-picker input {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    color: inherit !important;
    height: 48px !important;
  }
  .date-required .react-date-picker .react-date-picker__inputGroup__leadingZero {
    margin-right: 5px !important;
  }
  .date-required .react-date-picker input.react-date-picker__inputGroup__month,
  .date-required .react-date-picker input.react-date-picker__inputGroup__day {
    width: 18px !important;
    text-align: center !important;
    padding: 0 !important;
  }
  .date-required .react-date-picker__inputGroup__year {
    width: 34px !important;
    text-align: center !important;
    padding: 0 !important;
  }
  .date .react-date-picker input {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    color: inherit !important;
    height: 32px !important;
    font-size: 13px !important;
  }
  .date .react-date-picker .react-date-picker__inputGroup__leadingZero {
    margin-right: 2px !important;
    font-size: 13px;
  }
  .date .react-date-picker input.react-date-picker__inputGroup__month,
  .date .react-date-picker__inputGroup__day {
    width: 18px !important;
    text-align: center !important;
    padding: 0 !important;
  }
  .date .react-date-picker__inputGroup__year {
    width: 34px !important;
    text-align: center !important;
    padding: 0 !important;
  }
  .react-date-picker__calendar {
    width: 300px !important;
  }
  .react-date-picker__wrapper {
    border: none !important;
    margin-left: 0.4rem !important;
  }
  .react-calendar button.react-calendar__tile--active {
    color: black !important;
  }
  .react-date-picker--disabled {
    background-color: inherit !important;
    opacity: 0.5;
  }
  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background: rgb(27, 27, 27) !important;
  }
  .react-calendar__tile--now {
    background: rgb(80, 80, 80);
  }