
.title-container {
    padding: 0.5rem 0;
}
.border-custom {
    height: 2px;
    width: 100%;
    background-image: linear-gradient(to right, #000000, #281c1d, #4b312f, #6e4a3e, #90664b, #90664b, #90664b, #90664b, #6e4a3e, #4b312f, #281c1d, #000000);
}

